<template>
  <div class="RolePurview-list">
    <GlobalInfoBar title="角色权限" content="管理总平台账号访问的角色权限添加/编辑" />
    <div class="section">
      <div class="case-title">
        <div class="icon_title">
          <div>
            <img class="case-title_icon" src="@/assets/images/imgAll/icon_jsqx_@2x.png" alt />
          </div>
          <div class="case-title_max">数据列表</div>
        </div>
        <div>
          <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/systemManage/RoleAdd')">添加角色</el-button>
        </div>
      </div>
      <div class="line"></div>
      <div class="table">
        <RolePurviewTable />
      </div>
    </div>
  </div>
</template>

<script>
import RolePurviewTable from "./Table";
export default {
  name: "RolePurview-list",
  components: { RolePurviewTable }

};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.RolePurview-list {
  .section {
    border: 1xp solid red;
    width: 100%;
    background-color: white;
    .line{
      border: 1px solid #EDEFFD;
    }
    .table{
      padding: 20px;
    }
    .case-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      .icon_title {
        display: flex;
        .case-title_icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .case-title_max {
          display: inline-block;
          width: 100px;
          height: 17px;
          font-size: 18px;
          font-family: FZLanTingHei-B-GBK;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="GlobalTable">
      <el-table :data="ResidentDataList" border style="width: 100%" id="excelOrder">
          <el-table-column fixed prop="roleName" label="角色名称" align="center"></el-table-column>
          <el-table-column prop="num" label="账号数量" align="center"></el-table-column>
          <el-table-column prop="roleInfo" label="角色说明" align="center"></el-table-column>
          <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleBtn(scope.row, 'edit')">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleBtn(scope.row, 'danger')">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
    <!--弹框-->
    <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisible"
      :width="dialogWidth"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="onClose"
    >
      <div style="font-size:16px;color:#666666;">确认要执行该操作吗？</div>
    </GlobalDialog>
    <div>
      <el-dialog :close-on-click-modal="false" width="380px" title="删除角色" :visible="deleteVisible" @close="deleteClose" top="15%">
        <span style="font-size: 16px;font-weight: 400;color: #333333;">该角色下存在使用中账号，无法删除！</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteClose">知道了</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  data() {
    return {
      ResidentDataList: [],
      ResidentEnable: {
        id: "",
        isEnable: ""
      },
      //弹窗
      deleteVisible: false,
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      RoleId: "",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    // 表格切换页面
    handleCurrentChange() {
      _api.RoleList().then(res => {
        if (res.code === 1) {
          this.ResidentDataList = res.data;
          this.page.total = this.ResidentDataList.length
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      this.RoleId = row.id;
      if (type === "danger") {
        this.dialogVisible = true;
        this.dialogTitle = "删除角色";
      } else if (type === "edit") {
        // _api.RoleInfoRes({id:row.id}).then(res => {
        //   if(res.code === 1 ){
            this.$router.push({ path: "/systemManage/RoleAdd", query: { type: row.id } });
            // this.$store.commit("tagsView/POST_ROLEINFORES", res.data);
          }
        // })
      // }
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
      console.log(this.ResidentEnable.isEnabl);
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisible = false;
      _api.RoleDelete({ id: this.RoleId }).then(res => {
        if (res.code === 1) {
          this.$message.success('删除成功');
          this.handleCurrentChange();
        } else {
          this.deleteVisible = true;
        }
      });
    },
    deleteClose() {
      this.deleteVisible = false;
    },
    onClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
}
</style>
